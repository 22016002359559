
import Vue from 'vue';
import { Proposal } from '@/shared/types';
import { Services, Models } from '@/injectables/tokens';

import ProposalsTable from '@/components/Proposals/ProposalsTable.vue';
import ClientInfoDialog from '@/components/Clients/ClientInfoDialog.vue';
import AppBar from '@/widgets/proposals/proposals-app-bar.vue';
import { debounce } from 'lodash';
import { RouterModelContract } from '@/injectables';

export default Vue.extend({
  name: 'Proposals',

  useInjectable: [Services.Proposal, Models.Router],

  components: { ProposalsTable, ClientInfoDialog, AppBar },

  data: (): {
    selectedClientId: string | null;
    page: number;
  } => ({
    selectedClientId: null,
    page: 1,
  }),

  watch: {
    filters: {
      handler() {
        this.setFirstPage();
        this.getProposals();
      },
      deep: true,
    },
    searchTerm() {
      this.setFirstPage();
      this.getProposals();
    },
  },

  beforeMount() {
    const routerEntity = this.$container.get(Models.Router);
    const queryKeys = Object.keys(this.$route.query);

    if (!queryKeys.includes('itemsPerPage')) this.itemsPerPage = 15;

    if (queryKeys.length !== 0 && !(queryKeys.length === 1 && queryKeys.includes('itemsPerPage'))) return;

    const sorting = (routerEntity as RouterModelContract).getDefaultProposalSorting();
    this.updateRoute({ sorting });
  },

  mounted(): void {
    this.getProposals();
  },

  computed: {
    sorting() {
      const routerEntity = this.$container.get(Models.Router);
      const sorting = (routerEntity as RouterModelContract).getDefaultProposalSorting();
      const { sortBy, desc: sortDesc } = this.$route.query;

      const options = { ...sorting, sortBy, sortDesc };
      return {
        sortBy: options.sortBy,
        sortDesc: (routerEntity as RouterModelContract).stringToBoolean(options.sortDesc),
      };
    },
    options() {
      const { page, itemsPerPage } = this;
      return { ...this.sorting, page, itemsPerPage };
    },
    proposals(): Proposal[] {
      if (this.$store.state.proposal.allProposals?.list) {
        return this.$store.state.proposal.allProposals.list;
      }
      return [];
    },
    total(): number {
      if (this.$store.state.proposal.allProposals?.list) {
        return this.$store.state.proposal.allProposals.total;
      }
      return 0;
    },
    loadingProposals(): boolean {
      return this.$store.state['proposal'].loadingAllProposals;
    },
    filters() {
      const routerEntity = this.$container.get(Models.Router);
      const { status, startFrom, startTo, endFrom, endTo, modifiedFrom, modifiedTo } = this.$route.query;

      return (routerEntity as RouterModelContract).packProposalFilters(status, {
        start: [startFrom, startTo],
        end: [endFrom, endTo],
        modified: [modifiedFrom, modifiedTo],
      });
    },
    searchTerm() {
      return this.$route.query.searchKey;
    },
    itemsPerPage: {
      get(): number {
        const routerEntity = this.$container.get(Models.Router);
        return (routerEntity as RouterModelContract).getValidItemPerPage(this.$route.query.itemsPerPage);
      },
      set(itemsPerPage: number) {
        this.updateRoute({ itemsPerPage });
      },
    },
    skippedRows() {
      return (this.page - 1) * this.itemsPerPage;
    },
  },

  methods: {
    updateFilters(filters) {
      this.updateRoute({ filters });
    },
    updateSearch(searchTerm) {
      this.updateRoute({ searchTerm });
    },
    updateRoute({
      filters = this.filters,
      sorting = this.sorting,
      searchTerm = this.searchTerm,
      itemsPerPage = this.itemsPerPage,
    }) {
      const query = (this.routerEntity as RouterModelContract).getProposalFilteringQuery(
        filters,
        sorting,
        searchTerm,
        itemsPerPage,
      );
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },

    closeClientInfoDialog() {
      this.selectedClientId = null;
      if (!this.$store.state.client.clients.loaded) this.getProposals();
    },
    async openClientInfoDialog(id: string): Promise<void> {
      await this.$store.dispatch('client/updateActiveClientId', id);
      this.selectedClientId = id;
    },
    filter(proposal: Proposal) {
      return this.proposalService.filter(proposal, this.filters);
    },
    async changePage(page) {
      if (this.page === page) return;
      this.page = page;
      await this.getProposals();
    },
    async changeCountOfItems(itemsPerPage) {
      if (this.itemsPerPage === itemsPerPage) return;
      this.itemsPerPage = itemsPerPage;
      await this.getProposals();
    },
    async updateSort(sortBy) {
      if (this.sorting.sortBy === sortBy) return;
      this.updateRoute({ sorting: { ...this.sorting, sortBy } });
      await this.getProposals();
    },
    async updateSortType(sortDesc) {
      if (this.sorting.sortDesc === sortDesc) return;
      this.updateRoute({ sorting: { ...this.sorting, sortDesc } });
      await this.getProposals();
    },
    getProposals: debounce(function ({
      limit = this.itemsPerPage,
      offset = this.skippedRows,
      sortBy = this.sorting.sortBy,
      desc = this.sorting.sortDesc,
      searchTerm = this.searchTerm,
      filters = this.filters,
    } = {}) {
      return this.$store.dispatch('proposal/getAllProposals', {
        limit,
        offset,
        sortBy,
        desc,
        searchTerm,
        filters,
      });
    },
    300),
    setFirstPage() {
      this.options.page = 1;
    },
  },
});
